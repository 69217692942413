.wrapper {
    color: black;
    text-decoration: none;
    display: inline-block;
    padding: 15px 20px;
    font-size: 14px;
}

@media (min-width: 768px) {
    .wrapper {
        font-size: 20px;
    }

    .wrapper:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.1s ease-in-out;
    };
}
