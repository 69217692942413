:root {
    --tablet: 768px;
    --desktop: 1024px;
    --widescreen: 1280px;
}

body, h1 {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}