.headerWrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  background-color: white;
  z-index: 1;
}

@media (min-width: 768px) {
  .headerWrapper {
    flex-direction: row;
    padding: 20px;
  }
}

.nameWrapper {
  display: flex;
  flex-grow: 1;
  width: auto;
  padding: 12px 0;
}

@media (min-width: 768px) {
  .nameWrapper {
    padding: 0;
  }
}

.nameLogo {
  white-space: pre;
  font-size: 20px;
  font-weight: normal;
  color: black;
}

@media (min-width: 768px) {
  .nameLogo {
    font-size: 26px;
  }
}

.nameLogo:after {
  content: "|";
  color: orange;
  font-size: 32px;
  animation: blink 0.5s linear infinite alternate;
}

@-webkit-keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

@-moz-keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

@keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.linksWrapper {
  display: flex;
  padding-left: 0;
  align-items: center;
}

@media (min-width: 768px) {
  .linksWrapper {
    padding-left: 16px;
  }
}
