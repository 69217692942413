.contactWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px;
}

@media (min-width: 768px) {
  .contactWrapper {
    padding: 20px;
  }
}

.contactText {
  color: black;
  font-size: 14px;
  text-align: center;
}

.emailText {
  text-decoration: none;
}
