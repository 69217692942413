.photoWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
}

@media (min-width: 768px) {
  .photoWrapper {
    padding: 20px;
  }
}
