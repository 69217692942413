.imageWrapper {
  display: inline;
  margin: 4px 0;
}

.imageWrapper.hidden {
  display: none;
}

.imageWrapper.padded {
  margin: 4px 0 4px 12px;
}

@media (min-width: 768px) {
  .imageWrapper {
    margin: 2px 0;
  }

  .imageWrapper.padded {
    margin: 2px 0 2px 12px;
  }
}
