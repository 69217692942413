.homeWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
}

@media (min-width: 768px) {
  .homeWrapper {
    padding: 0 20px;
  }
}

.postWrapper {
  margin-top: 20px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  max-width: 800px;
}

.postTitle {
  font-weight: bold;
  font-size: 20px;
}

.postDate {
  margin: 4px 0;
  font-size: 12px;
  color: gray;
}

.postDescription {
  font-size: 12px;
}
